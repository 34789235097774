import { FormGroup } from '@angular/forms';
import { QueryPageModel } from '../query-page-model';

export class FilterSaleModel extends QueryPageModel {
    startDate: Date;
    endDate: Date;
    officeId: string;
    sourceId: string;
    stateId: string = null;

    timeZoneOffset: number;

    populateFromFormGroup(formGroup: FormGroup): void {
        this.startDate = formGroup.get('startDate').value;
        this.endDate = formGroup.get('endDate').value;
        this.officeId = formGroup.get('office').value == '' ? null : formGroup.get('office').value;
        this.sourceId = formGroup.get('source').value == '' ? null : formGroup.get('source').value;
        this.stateId = formGroup.get('state').value == '' ? null : formGroup.get('state').value;
    
        this.timeZoneOffset = new Date().getTimezoneOffset();
    }
}
