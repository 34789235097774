import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ISource } from '../interfaces/sources/source';
import { ISourceList } from 'src/app/interfaces/sources/source-list';
import { AddSourceModel } from '../models/sources/add-source-model';
import { IIdResult } from '../interfaces/id-result';
import { EditSourceModel } from '../models/sources/edit-source-model';
import { ISourceEdit } from '../interfaces/sources/source-edit';
import { ISelectResult } from '../interfaces/select-result';
import { IPage } from '../interfaces/page';
import { QueryPageModel } from '../models/query-page-model';

@Injectable({
    providedIn: 'root'
})

export class SourceService {
    private apiPath = environment.apiUrl + 'sources/';

    constructor(
        private http: HttpClient
    ) { }

    getByOffice(officeId: string): Observable<ISource[]> {
        return this.http.get<ISource[]>(this.apiPath + `by-office/${officeId}`)
            .pipe(map(result => {
                return result;
            }));
    }

    count(): Observable<number> {
        return this.http.get<number>(this.apiPath + 'count')
            .pipe(map(result => {
                return result;
            }));
    }

    getAll(): Observable<ISourceList[]> {
        return this.http.get<ISourceList[]>(this.apiPath)
            .pipe(map(result => {
                return result;
            }));
    }

    getPage(model: QueryPageModel): Observable<IPage<ISourceList>> {
        var headers = new HttpHeaders({
          'Content-Type': 'application/json',
        })
        return this.http.post<IPage<ISourceList>>(this.apiPath + 'page', JSON.stringify(model), { headers: headers })
          .pipe(map(result => {
            return result;
          }));
      }

    add(model: AddSourceModel): Observable<IIdResult> {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post<IIdResult>(this.apiPath, JSON.stringify(model), { headers: headers })
            .pipe(map(result => {
                return result;
            }));
    }

    update(id: string, model: EditSourceModel): Observable<any> {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.put<any>(this.apiPath + id, JSON.stringify(model), { headers: headers })
            .pipe(map(result => {
                return result;
            }));
    }

    delete(id: string): Observable<any> {
        return this.http.delete<any>(this.apiPath + id)
            .pipe(map(result => {
                return result;
            }));
    }

    get(id: string): Observable<ISourceEdit> {
        return this.http.get<ISourceEdit>(this.apiPath + id)
            .pipe(map(result => {
                return result;
            }));
    }

    getForSelection(): Observable<ISelectResult[]> {
        return this.http.get<ISelectResult[]>(this.apiPath + 'for-selection')
            .pipe(map(result => {
                return result;
            }));
    }

}
