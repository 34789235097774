import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { NotificationStore } from '../notifications/notifications.store';
import { Notification, NotificationLevel } from "../notifications/notification";
import { tap } from 'rxjs/operators';
import { IBadRequest } from '../interfaces/bad-request';
import { AuthService } from './auth.service';

@Injectable()
export class JwtErrorsInterceptor implements HttpInterceptor {

  constructor(
    private notificationStore: NotificationStore,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(tap(
      event => {
      },
      error => {
        if (error instanceof HttpErrorResponse && error.status == 401) {
          var badRequest = error.error as IBadRequest;
          if(badRequest != null) {
            badRequest.errors.forEach((e) => {
              this.notificationStore.push(new Notification(e, NotificationLevel.Error));
            });
          }
          else {
            let errorMessage = error.message;
            this.notificationStore.push(new Notification(errorMessage, NotificationLevel.Error));
          }
          this.authService.signout();
        }
      }
    ));
  }
}
