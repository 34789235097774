import { Component, OnInit, OnDestroy, AfterContentInit, ViewChild, ElementRef } from '@angular/core';

import { IOfficeTicker } from 'src/app/interfaces/offices/office-ticker';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TickerService } from 'src/app/services/ticker.service';
import { ThemeService } from 'src/app/services/theme.service';
import { IOfficeTickerData } from 'src/app/interfaces/offices/office-ticker-data';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormControl } from '@angular/forms';
import { IOfficeTheme } from 'src/app/interfaces/offices/office-theme';
import ShadeGenerator, { Shade } from 'shade-generator';
import { StyleSharedService } from 'src/app/shared/style-shared-service';

@Component({
  selector: 'ast-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss'],
})
export class TickerComponent implements OnInit, OnDestroy, AfterContentInit {

  private readonly size: number = 16;

  private dataEventSubscription = null;
  private updateEventSubscription = null;

  public total:number = 0;

  public viewControl = new FormControl('daily');

  public sourcesColors :any = [];
  public statesColors :any = [];

  private firstColor:string = '#1565C0';
  private secondColor:string = '#e65100';

  // private blueColors: string[] = ['#1565C0', '#1976D2', '#1d47f3'];
  // private orangeColors:string[] = ['#e65100', '#FF5722', '#E65100', ]

  private svgStyle: CSSStyleDeclaration;

  public color:string = 'red';


  // @ViewChild('svgImage', { static: true })
  // private svgImageRef: ElementRef<HTMLDivElement

  public tickerData: IOfficeTicker[];
  public data: IOfficeTickerData = {
    salesPerHour: 0,
    mostDealThisHour: "",
    topSourceThisHour: "",
    salesPerHourState: 0,
    tickerData: [],
    sources: [],
    states: [],
    topSourcesMonth: [],
    topSourcesWeek: [],
    topSaleDate: new Date()
  };


  public columns: number = 4;
  //public perHourClassName:string = 'per-hour-none';
  public isLoading:boolean = false;

  constructor(
    public tickerService: TickerService,
    private breakpointObserver: BreakpointObserver,
    private elementRef:ElementRef,
    private themeService:ThemeService,
    private styleSharedService:StyleSharedService
  ) { 
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 2;
      }
    });

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 3;
      }
    });

    breakpointObserver.observe([
      Breakpoints.WebLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.columns = 4;
      }
    });
  }
  ngAfterContentInit(): void {

    
  }




  public chatFormat(value:number):string
  {
    if (value % 1 === 0) {
      return value.toString();
    } else {
      return '';
    }

  }

  public getPerHourClass():string {
    if(this.data)
    {
      switch(this.data.salesPerHourState)
      {
        case 0:
          return 'per-hour-none';
        case 1:
          return 'per-hour-up'
        case 2:
          return 'per-hour-down'
        default: return '';
      }
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    console.log(screen.width);
    console.log(window.innerWidth);
    this.viewControl.disable();

    this.themeService.getTickerStyles(1).subscribe(
      result => {
        this.setStyles(result)
      },
      error => {
        
      },
      () => {
       
      }
    );

    this.view = screen.width > 650 ? undefined : [screen.width,0];
    this.showXAxisLabel = screen.width > 650;
    this.showYAxisLabel = screen.width > 650;
    
    this.dataEventSubscription = this.tickerService.dataRecievedEvent.subscribe(() => {
      this.setTickerData();
      this.data = this.tickerService.data;
      this.isLoading = false;
      this.viewControl.enable();
      this.setChartColors();
    });
    
    this.updateEventSubscription = this.tickerService.updateRecievedEvent.subscribe(() => {
      this.setTickerData();
      this.data = this.tickerService.data;
      this.setChartColors();

    });

    this.tickerService.startConnection();
    this.tickerService.addTickerListener();

    this.viewControl.valueChanges.subscribe(val => {
      this.setTickerData();
    })
  }

  private setStyles(styles:IOfficeTheme[]):void {
    styles.forEach(item => {
      this.elementRef.nativeElement.style.setProperty(`--${item.name}`, item.value);
    });


    this.firstColor = styles.find(x => x.name == "chartBarColor1").value;
    this.secondColor = styles.find(x => x.name == "chartBarColor2").value;

    this.setChartColors();
    this.styleSharedService.updateStyle(styles.filter(item =>  item.name.includes('logo') ));
  }

  private setTickerData() {
      let view = this.viewControl.value;
      var length = this.tickerService.data.tickerData.length < this.size ? this.tickerService.data.tickerData.length : this.size;
     

      switch(view)
      {
        case "daily": {
            this.tickerData = this.tickerService.data.tickerData
            .sort((a, b) => b.dailySalesCount - a.dailySalesCount)
            .slice(0, length);

            this.tickerData.forEach(element => {
              element.sales = element.dailySalesCount
            });

            break;
          }

          case "weekly": {
            this.tickerData = this.tickerService.data.tickerData
            .sort((a, b) => b.weeklySalesCount - a.weeklySalesCount)
            .slice(0, length);

            this.tickerData.forEach(element => {
              element.sales = element.weeklySalesCount
            });
            break;
          }

          case "monthly": {
            this.tickerData = this.tickerService.data.tickerData
            .sort((a, b) => b.monthlySalesCount - a.monthlySalesCount)
            .slice(0, length);

            this.tickerData.forEach(element => {
              element.sales = element.monthlySalesCount
            });
            break;
          }

        
      }

      let total =  this.data.tickerData.reduce((sum, current) => sum + current.sales, 0);
      if(isNaN(total))
        return 0;

      this.total = total;
  }

  ngOnDestroy(): void {
    if (this.dataEventSubscription) {
      this.dataEventSubscription.unsubscribe();
    }
    if (this.updateEventSubscription) {
      this.updateEventSubscription.unsubscribe();
    }
    this.tickerService.stopConnection();
  }




  private getRandomInt(value):number {
    return Math.floor(Math.random() * value);
  }

  setChartColors() {
    let result: any[] = [];
    
    for (let i = 0; i < this.data.sources.length; i++) {
       if(i % 2 == 0) {
        result.push({"name": this.data.sources[i].name, "value": ShadeGenerator.hue(this.firstColor).shade("90").hex()});
       } else {
        result.push({"name": this.data.sources[i].name, "value": ShadeGenerator.hue(this.secondColor).shade("200").hex()});
       } 
    }

    for (let i = 0; i < this.data.states.length; i++) {
      if(i % 2 == 0) {
        result.push({"name": this.data.states[i].name, "value": ShadeGenerator.hue(this.firstColor).shade("90").hex()});
      } else {
        result.push({"name": this.data.states[i].name, "value": ShadeGenerator.hue(this.secondColor).shade("200").hex()});
      } 
    }

    this.sourcesColors =  result;
    this.statesColors =  result;
 }
 
 // width:number = screen.width > 650 ? 0 : screen.width;
  //view: any[] = [this.width, 0];
  view:any[];

  

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Sources';
  showYAxisLabel = false;
  yAxisLabel = 'Sales';
  timeline = true;



 
  //pie
  showLabels = true;

  

}
