import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification } from './notification';

@Injectable({ providedIn: 'root' })
export class NotificationStore {
    private _subject = new Subject<Notification>();

    push(notification: Notification) {
        this._subject.next(notification);
    }
    
    getStream(): Observable<Notification> {
        return this._subject.asObservable();
    }
}



