<div *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-grid-list cols="2" rowHeight="fit" gutterSize="4">
    <mat-grid-tile>
        
        

        <div class="info-container">
            <a  class="show-sales" target="_blank" rel="noopener noreferrer" href="office-sales">Show sales</a>
            
            <span class="office-name">
                {{officeName}}
            </span>
            <!-- <h1 class="welcome">Welcome, {{userName}}</h1> -->
            <span class="sales-today">
                {{salesToday}}
            </span>
            <!-- <h1 class="sales-label">Sales Today</h1> -->
            <!-- <div class="show-sales" (click)="showSales()">See all sales</div>-->

          
        </div>
    </mat-grid-tile>

    <mat-grid-tile >

        <div class="submit-container">
            <div class="baby-ticker">
                <!-- <div class="baby-ticker-item">1</div>
                <div class="baby-ticker-item">2</div>
                <div class="baby-ticker-item">3</div> -->
                <div class="baby-ticker-item" *ngFor="let item of tickerData"> 
                    <span> {{item.name}}</span>
                    <span> {{item.sales}}</span>
                    <span> {{item.source}}</span>
                  
                </div>    
                

            </div>

            <form [formGroup]="addForm"  (ngSubmit)="add()">
                <mat-card>
                    
    
                    <mat-card-title class="submit-sale">
                        Submit a Sale
                    </mat-card-title>
    
                    <!-- <mat-card-subtitle>
                        Health Plan Through Verification / Primary NA
                    </mat-card-subtitle> -->
    
                    <mat-card-content>
       
                        <mat-form-field class="example-full-width">
                            <mat-label>Phone</mat-label>
                          
                            <!-- <input type="tel" id="tel" matInput class="form-control" phoneMask
                                [preValue]="addForm.value.phone" [phoneControl]="addForm.controls['phone']"
                                formControlName="phone" maxlength="14"> -->
    
                            <input type="tel" matInput placeholder="phone" formControlName="phone">
    
                            <mat-error *ngIf="phone.hasError('required')">
                                Phone can not be empty
                            </mat-error>
    
                            <mat-error *ngIf="phone.hasError('phone')">
                                Please enter a valid phone
                            </mat-error>
    
                            
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" formControlName="email">
                            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="email.hasError('required')">
                                Email can not be empty
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <mat-select formControlName="state">
                                <mat-option *ngFor="let state of states" [value]="state.id">
                                    {{state.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="state.hasError('required')">
                                State can not be empty
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Source</mat-label>
                            <mat-select formControlName="source">
                                <mat-option *ngFor="let source of sources" [value]="source.id">
                                    {{source.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="source.hasError('required')">
                                Source can not be empty
                            </mat-error>
                        </mat-form-field>
                        
                        <ng-container *ngIf="badRequest">
                            <mat-error *ngFor="let error of badRequest.errors">
                                {{ error }}
                            </mat-error>
                        </ng-container>
    
                    </mat-card-content>
    
                    <mat-card-actions align="end">
                        <button mat-flat-button class="report-button" [disabled]="isLoading" color="accent" type="submit">Submit</button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <div *ngIf="!isTickerLoading" class="top-sales-date">
                Time Since Last Sale: <span>{{lastTimeData}}</span>
            </div>

        </div>    

        
       
    </mat-grid-tile>
</mat-grid-list>