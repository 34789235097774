import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material-module';
import { AuthModule } from './auth/auth.module';
import { OfficeModule } from './office/office.module';
import { TickerModule } from './ticker/ticker.module';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { JwtErrorsInterceptor } from './auth/jwt-errors.interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationStore } from './notifications/notifications.store';
import { NotificationHttpInterceptor } from './notifications/notification.interceptor';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StyleSharedService } from './shared/style-shared-service';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AuthModule,
    OfficeModule,
    TickerModule
  ],
  providers: [
    StyleSharedService,
    {
      provide: APP_INITIALIZER,
      useFactory: (store: NotificationStore, injector: Injector) => () => {
        store.getStream().subscribe(notification => {
         
         const matSnackBar = injector.get(MatSnackBar);
         
         matSnackBar.open(notification.text, 'x', {
            duration: 5000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'center'
          });
        });
      },
      multi: true,
      deps: [NotificationStore, Injector]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotificationHttpInterceptor, multi: true, deps: [NotificationStore, Router] }
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
