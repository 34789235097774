import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SignInModel } from './sign-in/sign-in-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAccount } from 'src/app/interfaces/auth/account';
import { Router } from '@angular/router';
import { Policies } from './policies';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly accountKey: string = 'account';

  private apiAuth = environment.apiUrl + 'auth/';
  private account: IAccount;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.account = JSON.parse(localStorage.getItem(this.accountKey));
  }

  getName(): string {
    if (this.isSignedIn()) {
      return this.account.name;
    }
    else {
      return null;
    }
  }

  getEmail(): string {
    if (this.isSignedIn()) {
      return this.account.email;
    }
    else {
      return null;
    }
  }

  getRole(): string {
    if (this.isSignedIn()) {
      return this.account.role;
    }
    else {
      return null;
    }
  }

  getOfficeId(): string {
    if (this.isSignedIn()) {
      return this.account.officeId;
    }
    else {
      return null;
    }
  }

  getOffice(): string {
    if (this.isSignedIn()) {
      return this.account.office;
    }
    else {
      return null;
    }
  }

  getToken(): string {
    if (this.isSignedIn()) {
      return this.account.token;
    }
    else {
      return null;
    }
  }

  isSignedIn(): boolean {
    return this.account != null;
  }

  signin(model: SignInModel): Observable<IAccount> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<IAccount>(this.apiAuth + 'signin', JSON.stringify(model), { headers: headers })
      .pipe(map(account => {
        localStorage.setItem(this.accountKey, JSON.stringify(account));
        this.account = account;
        return account;
      }));
  }

  signout(): void {
    localStorage.removeItem(this.accountKey);
    this.account = null;
    //this.router.navigate(['/signin']);
    window.location.reload();
  }

  navigateToHome(): void {
    let path: string = '/signin';
    if (this.isSignedIn()) {
      switch (this.getRole()) {
        case Policies.Admin:
          path = '/admin/sales';
          break;
        case Policies.Office:
          if (this.getOfficeId() != null) {
            path = "/office";
          }
          // else {
          //   path = "/ticker";
          // }
          break;
        case Policies.Report:
          path = "/ticker";
          break;

        case Policies.TickerMonth:
          path = "/ticker_month";
          break;
      }
      // if (this.router.isActive(path, false)) {
      //   return;
      // }
    }
    this.router.navigate([path]);
  }
}
