<!-- <div class="total">Total:
    <span>{{tickerService.total}}</span>
</div> -->
<div class="container">
    
    <div class="mat-card-header" *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    
    <mat-grid-list [cols]="columns" rowHeight="fit" gutterSize="2">
        <mat-grid-tile *ngFor="let office of data">
            <ast-ticker-item [item]="office"></ast-ticker-item>
        </mat-grid-tile>
    </mat-grid-list>
</div>