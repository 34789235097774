import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IOfficeTheme } from '../interfaces/offices/office-theme';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OfficeThemeModel } from '../models/offices/office-theme-model';
import { IIdResult } from '../interfaces/id-result';

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    private apiPath = environment.apiUrl + 'theme/';

    constructor(
        private http: HttpClient
        ) { 
    
    }
    
    getTickerStyles(type: number): Observable<IOfficeTheme[]> {
        return this.http.get<IOfficeTheme[]>(this.apiPath + 'get-ticker-styles/' + type)
          .pipe(map(result => {
            return result;
          }));
    }

    setTickerStyles(models: OfficeThemeModel[]): Observable<IIdResult> {
        var headers = new HttpHeaders({
          'Content-Type': 'application/json',
        })
        return this.http.post<IIdResult>(this.apiPath + 'set-ticker-styles', JSON.stringify(models), { headers: headers })
          .pipe(map(result => {
            return result;
          }));
      }
}