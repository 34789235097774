import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AddSaleModel } from '../models/sales/add-sale-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISalesList } from '../interfaces/sales/sales-list';
import { FilterSaleModel } from '../models/sales/filter-sale-model';
import { IPage } from '../interfaces/page';
import { ISaleEdit } from '../interfaces/sales/sale-edit';
import { EditSaleComponent } from '../admin/sales/edit-sale/edit-sale.component';
import { EditSaleModel } from '../models/sales/edit-sale-model';

@Injectable({
  providedIn: 'root'
})

export class SalesService {
  private apiPath = environment.apiUrl + 'sales/';

  constructor(
    private http: HttpClient
  ) { }

  add(model: AddSaleModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<any>(this.apiPath, JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  countToday(officeId: string): Observable<number> {
    return this.http.get<number>(this.apiPath + `count-today/${officeId}`)
      .pipe(map(result => {
        return result;
      }));
  }

  countAll(officeId: string): Observable<number> {
    return this.http.get<number>(this.apiPath + `count-all/${officeId}`)
      .pipe(map(result => {
        return result;
      }));
  }

  getAll(): Observable<ISalesList[]> {
    return this.http.get<ISalesList[]>(this.apiPath)
      .pipe(map(result => {
        return result;
      }));
  }

  getPage(model: FilterSaleModel): Observable<IPage<ISalesList>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<IPage<ISalesList>>(this.apiPath + 'page', JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  exportFile(model: FilterSaleModel): Observable<HttpResponse<Blob>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    
    var result = this.http.post(this.apiPath + 'export-file', JSON.stringify(model),  {observe: 'response', headers: headers, responseType: 'blob' })
          .pipe(
            map(result => {
              return result; 
            }));

    return result;
  }

  count(): Observable<number> {
    return this.http.get<number>(this.apiPath + 'count')
      .pipe(map(result => {
        return result;
      }));
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(this.apiPath + id)
        .pipe(map(result => {
            return result;
        }));
  }

  update(id: string, model: EditSaleModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.put<any>(this.apiPath + id, JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  get(id: string): Observable<ISaleEdit> {
    return this.http.get<ISaleEdit>(this.apiPath + id)
        .pipe(map(result => {
            return result;
        }));
  }
}
