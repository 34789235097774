import { FormGroup } from '@angular/forms';

export class AddSaleModel {
    phone: string;
    email: string;
    stateId: string;
    sourceId: string;
    officeId: string;

    populateFromFormGroup(formGroup: FormGroup): void {
        this.phone = formGroup.get('phone').value;
        this.email = formGroup.get('email').value;
        this.stateId = formGroup.get('state').value;
        this.sourceId = formGroup.get('source').value;
        this.officeId = formGroup.get('office').value;
    }
}
