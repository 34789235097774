import { Component, OnInit, Input } from '@angular/core';
import { IOfficeTicker } from 'src/app/interfaces/offices/office-ticker';

@Component({
  selector: 'ast-ticker-item',
  templateUrl: './ticker-item.component.html',
  styleUrls: ['./ticker-item.component.scss']
})
export class TickerItemComponent implements OnInit {

  @Input() item: IOfficeTicker;

  constructor() { }

  ngOnInit(): void {
  }

}
