import { Injectable } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"
import { IOfficeTheme } from "../interfaces/offices/office-theme";

@Injectable()
export class StyleSharedService {
    
    
    val:IOfficeTheme[] = [];
    styleChanges = new BehaviorSubject<IOfficeTheme[]>([]);

    updateStyle(val:IOfficeTheme[]) {
        this.val = val;
        this.styleChanges.next(val);
    }
}
