import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISelectResult } from '../interfaces/select-result';


@Injectable({
  providedIn: 'root'
})

export class StateService {
  private apiPath = environment.apiUrl + 'states/';

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<ISelectResult[]> {
    return this.http.get<ISelectResult[]>(this.apiPath)
      .pipe(map(result => {
        return result;
      }));
  }

  getByOffice(officeId: string): Observable<ISelectResult[]> {
    return this.http.get<ISelectResult[]>(this.apiPath + `by-office/${officeId}`)
      .pipe(map(result => {
        return result;
      }));
  }
}