import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { StyleSharedService } from './shared/style-shared-service';
import { IOfficeTheme } from './interfaces/offices/office-theme';

@Component({
  selector: 'ast-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title: string;
  public color:string = 'blue';

  constructor(
    public authService: AuthService,
    private router: Router,
    private styleSharedService:StyleSharedService,
    private elementRef:ElementRef,
  ) { }

  ngOnInit(): void {
    this.title = environment.projectName;

    this.styleSharedService.styleChanges.subscribe((data:IOfficeTheme[]) => {
      data.forEach(item => {
        this.elementRef.nativeElement.style.setProperty(`--${item.name}`, item.value);
      });
    });

  }

  public showLogin() {
    let curUrl = this.router.url;
    if(curUrl.includes('ticker'))
    {
      return false;
    }

    return true;
  }
}
