import { FormGroup } from '@angular/forms';


export class AddSpendModel {
    officeId: string;
    totalSpend: number;
    totalUnited: number;
    totalACA: number;

    populateFromFormGroup(formGroup: FormGroup, officeId:string): void {
        this.totalSpend = Number(formGroup.get('totalSpend').value);
        this.totalUnited = Number(formGroup.get('totalUnited').value);
        this.totalACA = Number(formGroup.get('totalACA').value);
        this.officeId = officeId;
    }

}