import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { AuthService } from 'src/app/auth/auth.service';
import { IOfficeList } from 'src/app/interfaces/offices/office-list';
import { ISalesList } from 'src/app/interfaces/sales/sales-list';
import { ISourceList } from 'src/app/interfaces/sources/source-list';
import { IState } from 'src/app/interfaces/states/state';
import { FilterSaleModel } from 'src/app/models/sales/filter-sale-model';
import { OfficeService } from 'src/app/services/office.service';
import { SalesService } from 'src/app/services/sales.service';
import { SourceService } from 'src/app/services/source.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'ast-office-sales',
  templateUrl: './office-sales.component.html',
  styleUrls: ['./office-sales.component.scss', '../../admin/scss/table.component.scss']
})
export class OfficeSalesComponent implements OnInit {

  public displayedColumns: string[] = ['phone', 'office', 'source', 'email', 'state', 'date'];
  public dataSource: ISalesList[];
  public count: number;

  public offices:IOfficeList[];
  public sources:ISourceList[];
  public states:IState[];
  public isLoading: boolean = false;
  public valueAll: string = '';

  public model: FilterSaleModel = new FilterSaleModel();
  public total: number;
  public pageSizeOptions: number[] = [25, 50, 100];

  public filtersForm:FormGroup;

  constructor( 
    private salesService: SalesService,
    private sourceService: SourceService,
    private officeService: OfficeService,
    private authService: AuthService,
    private stateService: StateService) { }

  ngOnInit(): void {
    this.initFilterModel();
    this.initForm();
    
    this.loadSales();
    this.loadCount();

    // this.loadOffices();
    // this.loadSources();
    // this.loadStates();
  }
  
  initForm() {
    this.filtersForm = new FormGroup({
      'startDate': new FormControl(this.model.startDate, Validators.required),
      'endDate': new FormControl(this.model.endDate, Validators.required),
      'office': new FormControl(this.model.officeId),
      'source': new FormControl(this.model.sourceId),
      'state': new FormControl(this.model.stateId),
    });

    this.filtersForm.controls['office'].setValue(this.valueAll);
    this.filtersForm.controls['source'].setValue(this.valueAll);
    this.filtersForm.controls['state'].setValue(this.valueAll);
  }

  private initFilterModel(): void {
    this.model.startDate = new Date(new Date().toDateString());
    this.model.endDate = new Date(new Date().toDateString());
    
    this.model.pageNumber = 1;
    this.model.pageSize = this.pageSizeOptions[0];
    this.model.orderBy = 'created';
    this.model.isAscending = true;
  }

  private loadSales(): void {
    if (this.filtersForm.invalid) {
      return;
    }
    
    this.startLoading();
    
    this.model.populateFromFormGroup(this.filtersForm);
    this.model.officeId = this.authService.getOfficeId();

    this.salesService.getPage(this.model).subscribe(
      result => {
        this.dataSource = result.data;
        this.total = result.total;

        this.stopLoading;
      },
      error => {
        this.stopLoading;
      },
      () => {
        this.stopLoading();
      }
    );
  }

  private loadCount(): void {
    this.startLoading();
    this.salesService.countAll(this.authService.getOfficeId()).subscribe(
      result => {
        this.count = result;
        this.stopLoading;
      },
      error => {
        this.stopLoading;
      }
    );
  }

  private loadOffices(): void {
    this.startLoading();
    this.officeService.getAll().subscribe(
      result => {
        this.offices = result;
        this.stopLoading;
      },
      error => {
        this.stopLoading;
      }
    );
  }

  private loadSources(): void {
    this.startLoading();
    this.sourceService.getAll().subscribe(
      result => {
        this.sources = result;
        this.stopLoading;
      },
      error => {
        this.stopLoading;
      }
    );
  }

  private loadStates(): void {
    this.startLoading();
    this.stateService.getAll().subscribe(
      result => {
        this.states = result;
        this.stopLoading;
      },
      error => {
        this.stopLoading;
      }
    );
  }

  private startLoading() {
    this.isLoading = true;
    this.filtersForm.disable();
  }

  private stopLoading() {
    this.isLoading = false;
    this.filtersForm.enable();
  }

  public onPageChange(pageEvent: PageEvent) {
    this.model.pageNumber = pageEvent.pageIndex + 1;
    this.model.pageSize = pageEvent.pageSize;
    this.loadSales();
  }

  public onSortChange(sort: Sort) {
    this.model.pageNumber = 1;
    this.model.orderBy = sort.active;
    this.model.isAscending = sort.direction == 'asc';
    this.loadSales();
  }

  onFilter() {
    this.loadSales();
  } 
}
