import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OfficeComponent } from './office/office.component';
import { OfficeGuard } from '../auth/guards/office.guard';
import { OfficeSalesComponent } from './office-sales/office-sales.component';

const routes: Routes = [
  {
    path: 'office',
    component: OfficeComponent,
    canActivate: [OfficeGuard]
  },
  {
    path: 'office-sales', 
    component: OfficeSalesComponent,
    canActivate: [OfficeGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeRoutingModule { }
