<div class="header">
    <h1 class="mat-h1">Sales</h1>
    <mat-chip-list selectable="false" *ngIf="count">
        <mat-chip color="accent" selected>{{count}}</mat-chip>
    </mat-chip-list>
    <span class="fill-remaining-space"></span>
    
</div>


<mat-divider></mat-divider>

    <form [formGroup]="filtersForm" #formDir="ngForm">
        <mat-card>
            <div class="filter-container">
                <mat-form-field class="date">
                    <mat-label>Start date</mat-label>
                    <input matInput [matDatepicker]="startDatepicker" formControlName="startDate" (dateChange)="onFilter()">
                    <mat-datepicker-toggle matSuffix [for]="startDatepicker" ></mat-datepicker-toggle>
                    <mat-datepicker #startDatepicker></mat-datepicker>
                </mat-form-field>
                  
                <mat-form-field  class="date">
                    <mat-label>End date</mat-label>
                    <input matInput [matDatepicker]="endDatepicker" formControlName="endDate" (dateChange)="onFilter()">
                    <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatepicker></mat-datepicker>
                </mat-form-field>
    
                <!-- <mat-form-field  class="select">
                    <mat-label>Office</mat-label>
                    <mat-select formControlName="office" (selectionChange)="onFilter()">
                        <mat-option [value]="valueAll">
                            All
                        </mat-option>
                        <mat-option *ngFor="let office of offices"
                            [value]="office.id">
                            {{office.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field class="select">
                    <mat-label>Source</mat-label>
                    <mat-select formControlName="source" (selectionChange)="onFilter()">
                        <mat-option [value]="valueAll">
                            All
                        </mat-option>
                        <mat-option *ngFor="let source of sources"
                            [value]="source.id">
                            {{source.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field class="select">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state" (selectionChange)="onFilter()">
                        <mat-option [value]="valueAll">
                            All
                        </mat-option>
                        <mat-option *ngFor="let state of states"
                            [value]="state.id">
                            {{state.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
    
            </div>
        </mat-card>
    </form>

<div class="container mat-elevation-z1">
    <div class="table">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        
        <mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort [matSortActive]="model.orderBy"
            [matSortDirection]="model.isAscending ? 'asc' : 'desc'" matSortDisableClear
            (matSortChange)="onSortChange($event)">

            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef  mat-sort-header="phone"> Phone </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{sale.phone}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="office">
                <mat-header-cell *matHeaderCellDef mat-sort-header="office"> Office </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{sale.office}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="source">
                <mat-header-cell *matHeaderCellDef mat-sort-header="source"> Source </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{sale.source}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{sale.email}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef mat-sort-header="state"> State </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{sale.state}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{ sale.created | date: 'MM/dd/yyyy h:mm a' : 'EST'}} </mat-cell>
            </ng-container>    
            <!-- <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                <mat-cell *matCellDef="let sale"> {{sale.type}} </mat-cell>
            </ng-container> -->
            <!-- <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let sale">
                    <button mat-flat-button color="accent" class="icon-button" [routerLink]="[sale.id]">
                        <mat-icon>create</mat-icon>
                    </button>
                </mat-cell>
            </ng-container> -->
        
            <mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
    </div>
    <mat-paginator #paginator [length]="total" [pageSize]="model.pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>


